<template>
  <step-lesson-layout
    :title="$t('module1.part2.cumul.title')"
    :title-annotation="$t('module1.part2.cumul.intro')"
  >
    <template #content>
      <div class="lesson">
        <!-- Cumul par gestes -->
        <div class="app-block">
          <div class="app-block-title">{{ $t('module1.part2.cumul.gestes.title') }}</div>
          <div class="app-block-content">
            <!-- MPR + CDP -->
            <div class="cumul-section">
              <div class="cumul-head">
                <div class="logo-wrapper">
                  <img :src="require('@/assets/logos/aides/logo-mpr.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                </div>
                <p class="plus">
                  +
                </p>
                <div class="logo-wrapper">
                  <img :src="require('@/assets/logos/aides/logo-cee-cdp.svg')" :alt="$t('global.imageAlts.ceeLogo')">
                </div>
              </div>
              <p v-html="$t('module1.part2.cumul.gestes.mprCoupPouce')"></p>
              <mpr-montant-by-niveau-revenu :niveaux-revenus="niveauxRevenus" />
            </div>

            <div class="line"></div>

            <!-- MPR + Aides locales -->
            <div class="cumul-section">
              <div class="cumul-head">
                <div class="logo-wrapper">
                  <img :src="require('@/assets/logos/aides/logo-mpr.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                </div>
                <p class="plus">
                  +
                </p>
                <div class="logo-wrapper">
                  <img class="icon" :src="require('@/assets/module1/part3/illu-pinned-home.svg')">
                  <p class="title">{{ $t('module1.part2.aides.aideLocale.title') }}</p>
                </div>
              </div>
              <p v-html="$t('module1.part2.cumul.gestes.mprAideLocale1')"></p>
              <p v-html="$t('module1.part2.cumul.gestes.mprAideLocale2')"></p>
            </div>
          </div>
        </div>

        <!-- RENO AMPLEUR -->
        <div class="app-block">
          <div class="app-block-title">{{ $t('module1.part2.cumul.ampleur.title') }}</div>
          <div class="app-block-content">
            <!-- MPR + CDP -->
            <div class="cumul-section">
              <div class="cumul-head">
                <div class="logo-wrapper">
                  <img :src="require('@/assets/logos/aides/logo-mpr.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                </div>
                <p class="plus">
                  +
                </p>
                <div class="logo-wrapper">
                  <img :src="require('@/assets/logos/aides/logo-cee-cdp.svg')" :alt="$t('global.imageAlts.ceeLogo')">
                </div>
                <p class="plus">
                  =
                </p>
                <img src="@/assets/icons/cross-circled.svg" class="no-cumul-icon">
              </div>
              <p v-html="$t('module1.part2.cumul.ampleur.mprCoupPouce')"></p>
            </div>

            <div class="line"></div>

            <!-- MPR + Aides locales -->
            <div class="cumul-section">
              <div class="cumul-head">
                <div class="logo-wrapper">
                  <img :src="require('@/assets/logos/aides/logo-mpr.svg')" :alt="$t('global.imageAlts.maprimerenovLogo')">
                </div>
                <p class="plus">
                  +
                </p>
                <div class="logo-wrapper">
                  <img class="icon" :src="require('@/assets/module1/part3/illu-pinned-home.svg')">
                  <p class="title">{{ $t('module1.part2.aides.aideLocale.title') }}</p>
                </div>
              </div>
              <p v-html="$t('module1.part2.cumul.ampleur.mprAideLocale')"></p>
              <mpr-montant-by-niveau-revenu :niveaux-revenus="niveauxRevenusRenoAmpleur" />
              <p v-html="$t('module1.part2.cumul.ampleur.plafond')"></p>
            </div>
          </div>
        </div>

        <!-- REGLES INCOMPATIBILITE -->
        <div class="app-block">
          <div class="app-block-title">{{ $t('module1.part2.cumul.incompat.title') }}</div>
          <div class="app-block-content">
            <p v-html="$t('module1.part2.cumul.incompat.content')"></p>
            <img class="illu-incompat" src="@/assets/module1/part2/lesson/illu-incompatibilite.svg">
          </div>
        </div>

        <div class="app-decoration-block">
          <p>{{ $t('module1.part2.goToActivity') }}</p>
          <br>
          <app-button-layout @click="goToNextStep">
            {{ $t('training.global.goToActivity') }}
          </app-button-layout>
        </div>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout.vue'
import MprMontantByNiveauRevenu from '@/components/training/MprNiveauxRevenus.vue'
import AppButtonLayout from '@/components/buttons/AppButtonLayout.vue'
import useTrainingUtils from '@/utils/useTrainingUtils'

export default {
  name: 'Module1Part2Lesson4',
  components: { MprMontantByNiveauRevenu, StepLessonLayout, AppButtonLayout },
  setup () {
    const { goToNextStep } = useTrainingUtils()
    return { goToNextStep }
  },
  data () {
    return {
      niveauxRevenus: [
        {
          name: 'tresModeste',
          label: this.$t('training.mprMenages.tresModeste'),
          value: '90%'
        },
        {
          name: 'modeste',
          label: this.$t('training.mprMenages.modeste'),
          value: '75%'
        },
        {
          name: 'intermediaire',
          label: this.$t('training.mprMenages.intermediaire'),
          value: '60%'
        },
        {
          name: 'aise',
          label: this.$t('training.mprMenages.aise'),
          value: '40%'
        }
      ],
      niveauxRevenusRenoAmpleur: [
        {
          name: 'tresModeste',
          label: this.$t('training.mprMenages.tresModeste'),
          value: '100%'
        },
        {
          name: 'modeste',
          label: this.$t('training.mprMenages.modeste'),
          value: '80%'
        },
        {
          name: 'intermediaire',
          label: this.$t('training.mprMenages.intermediaire'),
          value: '60%'
        },
        {
          name: 'aise',
          label: this.$t('training.mprMenages.aise'),
          value: '40%'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.app-block-content {
  display: flex;
  flex-direction: column;
  gap: $space-m;
  .line {
    background-color: $c-background;
    width: 100%;
    height: 1px;
    display: inline-block;
  }
}
.illu-incompat {
  margin: 0 auto;
  max-width: 320px;
}
.app-decoration-block {
  max-width: 364px;
  margin: 20px auto 0 auto;
}
.cumul-section {
  display: flex;
  flex-direction: column;
  gap: $space-sm;
}
.logo-wrapper {
  margin-bottom: $space-s;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: $space-sm $space-m;
  border-radius: $radius-xl 0 $radius-xl 0;
  box-shadow: 0px 2px 1px #B5BABE29;
  border: 1px solid $c-background;
  img {
    width: 80px;
    max-width: 100%;
    max-height: 80px;
    &.icon {
      width: 25px;
    }
  }
  .title {
    text-align: center;
    margin-top: $space-s;
    font-size: $fz-xs;
    font-weight: $fw-l;
    color: $c-text-dark;
  }
}
.cumul-head {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-top: $space-s;
  .plus {
    color: $c-secondary;
    font-size: clamp(2rem, 9vw, 5rem);
    margin: 0 $space-s;
  }
  .no-cumul-icon {
    width: 40px;
  }
}
.app-block-title.red {
  background-color: $c-error;
}
</style>
